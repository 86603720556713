import React from 'react';
import { Trans } from 'react-i18next';
import why from '../../../assets/images/why.jpeg';
import mision from '../../../assets/images/mision.jpeg';
import vision from '../../../assets/images/vision.jpeg';
import companyValues from '../../../assets/images/companyValues.jpeg';


export const industriesList = (t) => [
  {
    id: 1,
    title: t('industriesCard1'),
    description: t('industriesCard1Description'),
    img: why,
  },
  {
    id: 2,
    title: t('industriesCard2'),
    description: t('industriesCard2Description'),
    img: mision,
    reverse: true
  },
  {
    id: 3,
    title: t('industriesCard3'),
    description: t('industriesCard3Description'),
    img: vision,
    reverse: true
  },
  {
    id: 4,
    title: t('industriesCard4'),
    description: <Trans i18nKey="industriesCard4Description" components={[<br />]} />,
    img: companyValues,
  },
];
