import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { CustomTitle } from '../../../components/CustomText';
import { useStyles } from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { CustomCard } from '../../../components/CustomCard';


export default function LetsGetToWork() {
  const classes = useStyles();
  const {t} = useTranslation();

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "letsGetWork.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);

  const data = {
    description: t('letsGetToWorkDescription'),
    img: imageData.file.childImageSharp.fluid
  };

  return (
    <Grid container justify={'center'} className={classes.container}>
      <Grid item xs={12} className={classes.aboutUsContainer}>
        <CustomTitle title={t('letsGetToWork')} />
      </Grid>
      <CustomCard img={data.img}>
        <Grid item xs={11}>
          <Typography variant={'body1'} align={'left'}
                      data-sal={'slide-down'}
                      data-sal-delay="300"
                      data-sal-duration="1000"
                      data-sal-easing="ease"
          >
            {data.description}
          </Typography>
          <Grid item sm={12} md={6}>
            <div data-sal={'zoom-in'}
                 data-sal-delay="300"
                 data-sal-duration="1000"
                 data-sal-easing="ease"
            >
              <Button
                variant={'contained'}
                className={classes.button}
                onClick={
                  () => {
                    setTimeout(() => {
                      document.querySelector('#contact-form').click();
                    });
                  }}
              >
                {t('contactUs')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CustomCard>
    </Grid>
  );
};
