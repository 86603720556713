import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image/withIEPolyfill';
// import { Add } from '@material-ui/icons';
import { useStyles } from './styles';


export function CustomCard({children, img}) {
  const classes = useStyles();

  return (
    <Grid item container justify={'center'} alignItems={'center'} className={classes.cardContainer}>
      <Grid item sm={5} xs={12} container justify={'flex-end'} className={classes.cardText}>
        {children}
      </Grid>
      <Grid item sm={6} xs={12} className={classes.imageContainer}>
        <Img fluid={img} objectFit="contain" className={classes.image} />
      </Grid>
    </Grid>
  );
};

export function CustomStoryCard({title, text, icon}) {
  const classes = useStyles();
  // const {t} = useTranslation();
  const Icon = icon;

  return (
    <Paper elevation={0} className={classes.storyCardContainer}>
      <Grid item xs={12}>
        <Icon className={classes.storyCardIcon} />
        <Typography variant={'subtitle1'} gutterBottom>
          {title}
        </Typography>
        <Typography variant={'body2'} gutterBottom>
          {text}
        </Typography>
      </Grid>
      {/*<div className={classes.storyCardLinkContainer}>*/}
      {/*  <Typography variant={'subtitle2'} gutterBottom className={classes.storyCardLink}>*/}
      {/*    {t('readStory')}*/}
      {/*  </Typography>*/}
      {/*  <Add color={'primary'} className={classes.storyCardAddIcon} />*/}
      {/*</div>*/}
    </Paper>
  );
};


export function CustomIndustryCard({title, text, img, reverse}) {
  const classes = useStyles();
  // const {t} = useTranslation();

  return (
    <Grid item container justify={'center'} alignItems={'center'} className={classes.h100}>
      <Grid
        item xs={12}
        container
        direction={reverse ? 'row-reverse' : 'row'}
        className={reverse ? classes.cardIndustryReverse : classes.cardIndustry}
      >
        <Grid item sm={6} xs={12} className={classes.cardIndustryText}>
          <Typography variant={'h5'} color={'secondary'} gutterBottom className={classes.cardIndustryTitle}>
            {title}
          </Typography>
          <Typography color={'secondary'} gutterBottom className={classes.cardIndustryDescription}>
            {text}
          </Typography>
          {/*<Button variant={'contained'} className={reverse ? classes.secondaryBtnReverse : classes.secondaryBtn}>*/}
          {/*  {t('readMore')}*/}
          {/*</Button>*/}
        </Grid>
        <Grid
          item sm={6} xs={12}
          className={reverse ? classes.imageIndustryContainerReverse : classes.imageIndustryContainer}
        >
          <img
            src={img}
            alt={'Card-background'}
            className={reverse ? classes.imageIndustryReverse : classes.imageIndustry}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
