 import React from 'react';
import Header from '../components/Header';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';
import Footer from '../components/Footer';
// import SeeAlso from '../sections/AboutUs/SeeAlso';
import AboutUsBanner from '../sections/AboutUs/Banner';
import OurIndustryExpertise from '../sections/AboutUs/OurIndustryExpertise';
import LetsGetToWork from '../sections/AboutUs/LetsGetToWork';

export default function AboutUsPage({location}) {
  const { state = {} } = location;
  const { subItemId = ''} = state || {};

  return (
    <Layout style={{
      backgroundColor: 'white'
    }} subItemId={subItemId}
    >
      <Seo title="About us" />
      <Header location={location} />
      <AboutUsBanner />
      <OurIndustryExpertise />
      {/*<SeeAlso />*/}
      <LetsGetToWork />
      <Footer />
    </Layout>
  );
}
