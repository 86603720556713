import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridAutoRows: '1fr',
    gridGap: '2rem',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridAutoRows: 'min-content',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridAutoRows: 'min-content',
    },
  },
  container: {
    padding: '5rem 18.5rem 6.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '5rem 4.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 1.5rem',
    },
  },
  description: {
    color: 'black',
    padding: '2rem 14rem',
  },
  titleContainer: {
    paddingBottom: '2rem',
  },
  serviceContainer: {
    padding: '4rem',
  },
  cardContainer: {
    cursor: 'pointer',
    borderRadius: 15,
    padding: '1rem 2rem',
    border: `1px solid ${theme.palette.border}`,
    backgroundColor: theme.palette.cardWhite,
    textAlign: 'center',
    height: '100%',
  },
  textContainer: {
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  imageCard: {
    padding: theme.spacing(1),
    height: '100%',
    width: '100%',
    minHeight: '30rem',
    [theme.breakpoints.down('sm')]: {
      minHeight: '26rem',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'fit-content',
      marginTop: '1rem',
      padding: 0,
    },
  },
  cardMenuContainer: {
    flexGrow: 1,
    margin: 0,
    height: '24rem',
    [theme.breakpoints.only('sm')]: {
      height: 'min-content'
    },
    [theme.breakpoints.only('lg')]: {
      height: '26rem',
    },
    [theme.breakpoints.only('xs')]: {
      overflow: 'auto',
      height: 'min-content',
      flexWrap: 'nowrap'
    },
  }
}));
