import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import Banner from '../../../components/Banner';
import { useStyles } from './styles';


export default function AboutUsBanner() {
  const classes = useStyles();
  const {t} = useTranslation();

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "aboutUsBG.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);


  return (
    <Banner img={imageData.file.childImageSharp.fluid} header>
      <Grid container className={classes.industriesContainer} spacing={1} justify={'center'}>
        <Grid item xs={12}>
          <Typography
            color={'secondary'}
            variant={'h4'}
            gutterBottom
            data-sal={'slide-down'}
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            {t('aboutUs')}
          </Typography>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Typography
            color={'secondary'}
            variant={'h5'}
            gutterBottom className={classes.industriesText}
            data-sal={'slide-down'}
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            {t('aboutUsDescription')}
          </Typography>
        </Grid>
      </Grid>
    </Banner>
  );
};
