import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CustomIndustryCard } from '../../../components/CustomCard';
import { industriesList } from './constants';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export default function OurIndustryExpertise() {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Grid container justify={'center'} className={classes.container}>
      <Grid item container justify={'center'} xs={12} className={classes.titleContainer}>
        <Grid item sm={8} xs={12}>
          <Typography
            variant={'h4'}
            color={'primary'}
            align={'center'}
            data-sal={'slide-down'}
            data-sal-delay="300"
            data-sal-duration="1000"
            data-sal-easing="ease"
          >
            {t('ourIndustryDescription')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item lg={6} md={8} sm={9} xs={12} className={classes.textContainer}>
        <Typography
          variant={'body2'}
          gutterBottom
          align={'center'}
          data-sal={'slide-down'}
          data-sal-delay="300"
          data-sal-duration="1000"
          data-sal-easing="ease"
        >
          {t('ourIndustrySubDescription')}
        </Typography>
      </Grid>
      <div className={classes.root}>
        {industriesList(t).map((industry) => (
          <div
            key={`industry-card-${industry.id}`}
            className={classes.imageCard}
            data-sal={'zoom-in'}
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <CustomIndustryCard
              title={industry.title}
              text={industry.description}
              img={industry.img}
              reverse={industry.reverse}
            />
          </div>
        ))}
      </div>
    </Grid>
  );
};
