import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  title: {
    width: 'fit-content',
  },
  titleFocus: {
    fontWeight: 'bold',
    padding: '0 .5rem',
  },
  image: {
    width: '100% !important',
    height: '100% !important',
  },
  cardText: {
    [theme.breakpoints.down('md')]: {
      padding: '20px'
    },
  },
  imageContainer: {
    height: '21rem',
    padding: '0 50px',
    [theme.breakpoints.down('md')]: {
      padding: '20px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '17rem',
      padding: '0px'
    },
  },
  storyCardContainer: {
    padding: theme.spacing(4),
    display: 'flex',
    alignContent: 'space-between',
    flexDirection: 'column',
    borderRadius: 10,
    border: `1px solid ${theme.palette.border}`,
    width: '100%',
  },
  storyCardIcon: {
    marginBottom: theme.spacing(3),
  },
  storyCardLink: {
    marginBottom: 0,
    color: theme.palette.primary.main,
    fontWeight: 300,
    opacity: 1,
    paddingRight: theme.spacing(1),
    cursor: 'pointer',
  },
  storyCardLinkContainer: {
    paddingTop: theme.spacing(2),
    marginTop: 'auto',
    maxHeight: 'min-content',
    display: 'flex',
    alignItems: 'center'
  },
  storyCardAddIcon: {
    cursor: 'pointer',
    fontSize: 20,
  },
  cardIndustry: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 15,
    color: theme.palette.secondary.main,
    height: '100%',
  },
  cardIndustryReverse: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 15,
    color: theme.palette.secondary.main,
    height: '100%',
  },
  cardIndustryText: {
    padding: '1rem 3rem',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 3rem',
    },
  },
  cardIndustryTitle: {
    paddingBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  cardIndustryDescription: {
    fontSize: 13,
    lineHeight: 1.65,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 15,
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
    },
  },
  imageIndustryContainer: {
    backgroundColor: theme.palette.secondary.main,
    borderTopRightRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      height: '15rem',
      borderTopLeftRadius: 'initial',
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
  imageIndustryContainerReverse: {
    backgroundColor: theme.palette.secondary.main,
    borderTopLeftRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '15rem',
      borderTopLeftRadius: 'initial',
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
  },
  imageIndustry: {
    borderTopRightRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      borderBottomRightRadius: 'inherit',
      borderTopRightRadius: 'initial',
      borderBottomLeftRadius: 'inherit',
    },
  },
  imageIndustryReverse: {
    borderTopRightRadius: 'initial',
    borderTopLeftRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
    width: '100% !important',
    height: '100% !important',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      borderBottomRightRadius: 'inherit',
      borderTopRightRadius: 'initial',
      borderBottomLeftRadius: 'inherit',
      borderTopLeftRadius: 'none',
    },
  },
  industryCardContainer: {
    height: '100%',
  },
  // Button
  secondaryBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    fontSize: 16,
    borderRadius: 10,
    marginTop: theme.spacing(2),
    minWidth: 'max-content'
  },
  secondaryBtnReverse: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.light,
    fontSize: 16,
    borderRadius: 10,
    marginTop: theme.spacing(2),
    minWidth: 'max-content'
  },
  h100: {
    height: '100%',
  }
}));
