import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    // padding: '4rem 18.5rem 12rem',
    // TODO: remove when see also section will be active
    clipPath: 'polygon(0 8rem, 100% 0, 100% 100%, 0% 100%)',
    padding: '15rem 18.5rem 10rem',
    backgroundColor: theme.palette.secondary.light,
    // --- end ---
    [theme.breakpoints.down('md')]: {
      // padding: '7rem 4.5rem',
      padding: '12rem 4.5rem 9rem', // rm
    },
    [theme.breakpoints.down('sm')]: {
      // padding: '7rem 1.5rem',
      padding: '9rem 2.5rem 5rem', // rm
      clipPath: 'polygon(0 5rem, 100% 0, 100% 93%, 0% 100%)', // rm
    },
    [theme.breakpoints.down('xs')]: {
      // padding: '7rem 1.5rem',
      padding: '9rem 1.5rem 5rem', // rm
      clipPath: 'polygon(0 5rem, 100% 0, 100% 98%, 0% 100%)', // rm
    },
  },
  textContainer: {
    paddingTop: theme.spacing(4.5)
  },
  description: {
    paddingTop: theme.spacing(2)
  },
  formContainer: {
    paddingTop: theme.spacing(8)
  },
  itemContainer: {
    paddingTop: theme.spacing(5)
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    width: '100%',
    textTransform: 'uppercase',
    padding: '.5rem 1rem',
    borderRadius: 10,
    marginTop: theme.spacing(3),
    marginLeft: 'auto',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      borderColor: 'white',
    },
  },
  aboutUsContainer: {
    paddingBottom: '3rem',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '1rem',
    },
  }
}));
